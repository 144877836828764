<template>
  <el-dialog
    v-model="visible"
    title="导出条件"
    width="360px"
    @close="visible = false"
  >
    <el-form ref="form" :model="ruleForm" :rules="rules">
      <el-form-item prop="date">
        <el-date-picker
          v-model="ruleForm.date"
          type="month"
          value-format="YYYY-MM"
          placeholder="选择导出月份"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">导出</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { ref, watch, defineEmits, reactive } from 'vue'
import { getStockApi } from './fetch'
import { exportStore } from './exportOrder'
import dayjs from 'dayjs'
const form = ref(null)
const visible = ref(true)
const emit = defineEmits(['close'])

const ruleForm = reactive({
  date: dayjs().add(-1, 'month').format('YYYY-MM')
})
const rules = reactive({
  date: [
    { required: true, message: '请输入', trigger: ['blur', 'change'] }
  ]
})

watch(visible, (newVal) => {
  if (!newVal) {
    emit('close')
  }
})

const handleSubmit = async () => {
  form.value.validate(async valid => {
    if (valid) {
      const res = await getStockApi({
        page: 1,
        rows: 999999,
        startDate: dayjs(ruleForm.date).startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs(ruleForm.date).endOf('month').format('YYYY-MM-DD')
      })
      if (res.data.length) {
        exportStore(res.data, ruleForm.date + '成品入库')
        emit('close')
        ElMessage({
          message: '导出成功！',
          type: 'success'
        })
      } else {
        ElMessage({
          message: '暂无数据',
          type: 'warning'
        })
      }
    } else {
      ElMessage({
        message: '请填写完整',
        type: 'error'
      })
    }
  })
}

</script>
