import Request from '@/utils/request'

export function getStockApi (data) {
  return Request({
    url: '/product/stock/list',
    params: data
  })
}

export function getAllTypeApi () {
  return Request({
    url: '/product/type/all'
  })
}

export function getAllProducerApi () {
  return Request({
    url: '/product/producer/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/product/specsbytype',
    params: data
  })
}

export function saveStockApi (data) {
  return Request({
    url: '/product/stock/add',
    method: 'post',
    data
  })
}

export function getStockDetailApi (data) {
  return Request({
    url: '/product/stock/detail',
    params: data
  })
}

export function getTypesApi () {
  return Request({
    url: '/product/type'
  })
}

export function delStockApi (data) {
  return Request({
    url: '/product/stock/delete',
    params: data
  })
}
