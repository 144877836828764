<template>
  <div v-loading="loading" class="stockList">
    <div class="top">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane
          v-for="(item, index) in typeList"
          :key="index"
          :label="`${item.productTypeName}`"
          :name="item._id"
        ></el-tab-pane>
      </el-tabs>
      <el-button @click="handleAdd" type="primary" style="margin-right: 20px">新增库存</el-button>
      <el-select v-if="activeName !== 'all'" clearable filterable placeholder="全部规格" v-model="specsFilter" @change="getList()">
        <el-option v-for="(item,index) in specsList" :key="index" :label="item.productSpecsName" :value="item._id"></el-option>
      </el-select>
      <el-select v-model="producerFilter" clearable filterable placeholder="制袋人" @change="getList()">
        <el-option v-for="(item,index) in producerList" :key="index" :label="item.producerName" :value="item._id"></el-option>
      </el-select>
      <el-date-picker
        style="margin: 0 20px;"
        v-model="dateFilter"
        type="daterange"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getList()"
      />
      <el-button @click="exportDialogShow = true" type="primary" style="margin-right: 20px">导出Excel</el-button>

    </div>
    <el-table ref="table" class="table" :data="tableData" :height="tableH" >
      <el-table-column prop="stockId" label="批次号" />
      <el-table-column prop="stockTime" width="100px" label="入库时间" />
      <el-table-column prop="productTypeName" label="类别" />
      <el-table-column prop="productSpecsName" width="150px" label="规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="price" label="单价" />
      <el-table-column v-if="userInfo.root" prop="money" label="总金额" />
      <el-table-column prop="number" label="件数" />
      <el-table-column prop="producer" label="制袋人" />
      <el-table-column prop="remark" label="备注" />
      <el-table-column prop="createTime" label="创建时间" width="180" />
      <el-table-column label="操作" width="100px" >
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row._id)">编辑</el-button>
          <el-button type="text" @click="handleDel(scope.row._id)" style="color:#F56C6C;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      :page-size="20"
      layout="total, prev, pager, next"
      @current-change="handleCurrentChange"
      :total="total"
    />

    <EditDialog
      v-if="editDialogShow"
      :id="currentId"
      :refresh-list="getList"
      @close="handleDialogclose"
    />
    <ExportDialog v-if="exportDialogShow" @close="exportDialogShow = false"></ExportDialog>

  </div>
</template>

<script>
import { reactive, toRefs, ref, nextTick, onMounted, inject } from 'vue'
import EditDialog from './edit.vue'
import {
  getStockApi,
  getTypesApi,
  delStockApi,
  getSpecByTypeApi,
  getAllProducerApi
} from './fetch'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
import ExportDialog from './exportDialog.vue'

export default {
  name: 'Home',
  components: {
    EditDialog,
    ExportDialog
  },
  setup () {
    const $msg = inject('$msg')
    const table = ref(null)
    const userInfo = store.state.userInfo
    const state = reactive({
      exportDialogShow: false,
      tableData: [],
      currentPage: 1,
      typeList: [],
      specsList: [],
      specsFilter: undefined,
      dateFilter: [],
      producerFilter: undefined,
      activeName: 'all',
      total: 0,
      tableH: 0,
      loading: false,
      editDialogShow: false,
      currentId: undefined,
      producerList: []
    })

    onMounted(async () => {
      const res = await getTypesApi()
      state.typeList = res.data
      await getList()
      getHeight()
      // 获取购入单位列表
      state.producerList = await getAllProducerApi()
    })

    const getHeight = () => {
      state.tableH = table.value.$el.offsetHeight
    }

    window.onresize = () => {
      nextTick(() => {
        getHeight()
      })
    }

    const getList = async (id) => {
      try {
        state.loading = true
        const startDate = state.dateFilter ? state.dateFilter[0] : undefined
        const endDate = state.dateFilter ? state.dateFilter[1] : undefined
        const res = await getStockApi({
          productTypeId: id || (state.activeName === 'all' ? undefined : state.activeName),
          productSpecsId: state.specsFilter,
          page: state.currentPage,
          producer: state.producerFilter,
          startDate,
          endDate,
          rows: 20
        })
        state.tableData = res.data
        state.total = res.total
      } catch (e) {
        $msg({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }

    const handleAdd = () => {
      state.editDialogShow = true
    }

    const handleDialogclose = () => {
      state.editDialogShow = false
      state.currentId = undefined
    }

    const handleEdit = (id) => {
      state.currentId = id
      state.editDialogShow = true
    }

    const handleCurrentChange = async () => {
      try {
        const id = state.activeName === 'all' ? undefined : state.activeName
        await getList(id)
      } catch (e) {
        ElMessage({
          type: 'error',
          message: e
        })
      }
    }

    const handleTabClick = async (val) => {
      if (val.paneName === 'all') {
        getList()
      } else {
        const id = val.paneName
        getList(id)
        state.specsList = await getSpecByTypeApi({ productTypeId: id })
      }
    }
    const handleDel = id => {
      ElMessageBox.confirm(
        '确定删除该条数据吗？',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          await delStockApi({
            id
          })
          $msg({
            message: '删除成功！',
            type: 'success'
          })
          await getList()
        })
        .catch((e) => {
          if (e !== 'cancel') {
            ElMessage({
              type: 'error',
              message: e.msg || e
            })
          }
        })
    }

    return {
      ...toRefs(state),
      getList,
      userInfo,
      table,
      handleAdd,
      handleDialogclose,
      handleEdit,
      handleCurrentChange,
      handleTabClick,
      handleDel
    }
  }
}
</script>

<style lang="stylus" scoped>
.stockList {
  padding 20px 20px 0 !important
  height: 100%;
  display: flex;
  flex-direction: column;

  .table {
    height: 0;
    flex: 1;
  }

  .pagination {
    text-align: center;
    padding 10px
  }
}
</style>
