<template>
  <el-dialog v-model="visible" :title="title" width="640px" class="add" :close-on-click-modal="false" :close-on-press-escape="false" @close="visible = false">
    <el-form ref="form" v-loading="loading" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="productTypeId">
        <el-select v-model="ruleForm.productTypeId" filterable placeholder="请选择" @change="onChange">
          <el-option v-for="(item,index) in types" :key="index" :label="item.productTypeName" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="productSpecsId">
        <el-select :disabled="!ruleForm.productTypeId" filterable v-model="ruleForm.productSpecsId" placeholder="请选择">
          <el-option v-for="(item,index) in specs" :key="index" :label="item.productSpecsName" :value="item._id"></el-option>
        </el-select>
        <el-button :disabled="!ruleForm.productSpecsId" type="text" style="margin-left: 10px;" @click="useSpecPrice">使用规格单价(¥{{curSpecPrice}})</el-button>
      </el-form-item>
      <el-form-item label="单价" prop="price">
        <el-input  v-model="ruleForm.price" type="number"></el-input>
      </el-form-item>
      <el-form-item label="数量" prop="quantity">
        <el-input v-model="ruleForm.quantity" type="number"></el-input>
      </el-form-item>
      <el-form-item label="价值金额" >
        {{money}}
      </el-form-item>
      <el-form-item label="件数" prop="number">
        <el-input v-model="ruleForm.number" type="number"></el-input>
      </el-form-item>
      <el-form-item label="入库时间" prop="stockTime">
        <el-date-picker v-model="ruleForm.stockTime" type="date" value-format="YYYY-MM-DD" placeholder="请选择"></el-date-picker>
      </el-form-item>
      <el-form-item label="制袋人" prop="producer">
        <el-select v-model="ruleForm.producer" filterable placeholder="请选择">
          <el-option v-for="(item,index) in producerList" :key="index" :label="item.producerName" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" >
        <el-input v-model="ruleForm.remark" ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted } from 'vue'
import * as math from 'mathjs'
import {
  getAllTypeApi,
  getSpecByTypeApi,
  saveStockApi,
  getAllProducerApi,
  getStockDetailApi
} from './fetch'

export default ({
  props: {
    refreshList: Function,
    id: String
  },
  setup (props) {
    const form = ref(null)
    const state = reactive({
      visible: true,
      loading: false,
      ruleForm: {
        productTypeId: '',
        productSpecsId: '',
        quantity: undefined,
        price: undefined,
        number: undefined,
        producer: '',
        stockTime: '',
        remark: ''
      },
      manualInput: false,
      types: [],
      specs: [],
      producerList: [],
      rules: {
        productTypeId: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        productSpecsId: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        number: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        stockTime: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        producer: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.loading = true
      state.types = await getAllTypeApi()
      state.producerList = await getAllProducerApi()
      if (props.id) {
        state.ruleForm = await getStockDetailApi({
          id: props.id
        })
        state.ruleForm.price = +state.ruleForm.price
        state.specs = await getSpecByTypeApi({ productTypeId: state.ruleForm.productTypeId })
      }
      state.loading = false
    })

    const title = computed(() => {
      return props.id ? '编辑入库' : '新增入库'
    })

    const money = computed(() => {
      if (state.ruleForm.price && state.ruleForm.quantity) {
        return math.multiply(state.ruleForm.price, state.ruleForm.quantity).toFixed(2)
      } else {
        return 0
      }
    })

    const curSpecPrice = computed(() => {
      return state.specs.find(bar => bar._id === state.ruleForm.productSpecsId)?.productSpecPrice || ''
    })
    const switchManualInput = () => {
      state.manualInput = !state.manualInput
    }

    const onSubmit = async () => {
      try {
        await form.value.validate()
        const data = _.cloneDeep(state.ruleForm)
        data.quantity = +data.quantity
        data.number = +data.number
        data.price = +data.price
        await saveStockApi({
          ...data,
          money: +money.value
        })
        state.visible = false
        props.refreshList()
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    const onChange = async (id) => {
      state.ruleForm.productSpecsId = ''
      state.specs = await getSpecByTypeApi({ productTypeId: id })
    }

    const useSpecPrice = () => {
      state.ruleForm.price = state.specs.find(bar => bar._id === state.ruleForm.productSpecsId)?.productSpecPrice
    }

    return {
      ...toRefs(state),
      form,
      curSpecPrice,
      title,
      onSubmit,
      money,
      onChange,
      switchManualInput,
      useSpecPrice
    }
  }
})
</script>
